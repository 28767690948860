* {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  direction: rtl;
}

body {
  margin: 0;
  padding: 0;
  background-color: rgb(255, 255, 103);
  overflow-x: hidden;
}

.breadcrumb {
  margin: 0;
  padding: 0.2rem;
  padding-right: 0.4rem;
  padding-top: 0.1rem;
  background-color: rgb(245, 245, 245);
}

.camera-icon {
  margin-top: 0.3rem;
  font-size: 1.2rem;
  color: rgb(255, 145, 0);
}

.border-class {
  border-bottom: 3px solid rgb(255, 145, 0);
}

.title {
  text-align: right;
  font-weight: bold;
}

.expand:hover {
  transform: scale(1.1);
  transition: 400ms ease-in-out;
  cursor: pointer;
}

.link {
  width: 100%;
  color: black;
  font-size: 1rem;
  border: 0px solid green;
}

@media screen and (min-width: 282px) and (max-width: 400px) {
  .link {
    width: 80%;
    border: 0px solid lightblue;
  }
}

@media screen and (min-width: 401px) and (max-width: 499px) {
  .link {
    width: 70%;
    border: 0px solid salmon;
  }
}

@media screen and (min-width: 500px) and (max-width: 580px) {
  .link {
    width: 50%;
    border: 0px solid purple;
  }
}

.link:hover {
  text-decoration: none;
  color: rgb(255, 145, 0);
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: rgb(39, 39, 39);
}

.about {
  color: white;
}

.about:hover {
  text-decoration: none;
  color: rgb(255, 145, 0);
}

.about-row {
  margin: 0 20% 6% 20%;
  background-color: rgb(39, 39, 39);
  color: white;
  border-radius: 2rem;
  font-size: 95%;
}

.about-row h1 {
  font-size: 200%;
}

.about-row img {
  border: 1px solid rgb(230, 187, 194);
  height: 25%;
  width: 25%;
  border-radius: 1.5rem;
  user-select: none;
}

.gallery-row {
  margin-bottom: 5rem;
  user-select: none;
}

.gallery-figure {
  width: 12rem;
  height: 11rem;
  padding: 0;
  margin: 0.5rem;
  overflow: hidden;
}

.gallery-figure img {
  width: 220%;
  height: 220%;
}

.wrapper {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  /* height: 35rem; */
  width: 100vw;
  height: 100vh;
  margin-bottom: 5rem;
  background-color: rgb(39, 39, 39);
}

.prev, .next {
  width: 2rem;
  height: 10rem;
  color: rgb(255, 255, 103);
  cursor: pointer;
}

.slider {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 73%;
  height: 100%;
  max-height: 100%;
  margin: 0 2rem;
}

.cancel {
  display: flex;
  justify-content: right;
  font-size: 1.5rem;
}

.cancel-link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  color: white;
}

.pic-container {
  display: none;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.active {
  display: block;
}

.pic-container img {
  max-width: 100%;
  max-height: 85%;
  margin-top: 0rem;
  user-select: none;
}

@media screen and (min-width: 800px) and (max-width: 891px) {
  .pic-container {
    width: 100%;
    height: 80%;
    max-height: 100%;
  }
}

@media screen and (max-width: 800px) {
  .about-row img {
    height: 50%;
    width: 50%;
  }

  .wrapper {
    height: 100vh;
  }

  .cancel {
    top: 9%;
    margin-top: 0;
  }

  .prev, .next {
    display: none;
  }

  .slider {
    height: 100%;
    width: 100vw;
    margin: 0;
  }

  .pic-container img {
    margin-top: 20%;
    height: 60%;
  }
}

@media screen and (max-height: 815px) and (max-width: 800px) {
  .about-row {
    font-size: 90%;
    margin-bottom: 6rem;
  }

  .about-row h1 {
    font-size: 120%;
    font-weight: bold;
  }

  .about-row img {
    height: 90%;
    width: 90%;
  }

  .cancel {
    top: 12%;
  }

  .pic-container img {
    margin-top: 31%;
    height: 52%;
  }
}

@media screen and (max-height: 700px) and (max-width: 800px) {
  .about-row {
    font-size: 90%;
    margin-bottom: 5rem;
  }

  .about-row h1 {
    font-size: 120%;
    font-weight: bold;
  }

  .about-row img {
    height: 90%;
    width: 90%;
  }

  .cancel {
    margin-top: 2%;
  }

  .pic-container img {
    margin-top: 23%;
    height: 52%;
  }
}